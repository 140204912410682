import request from '@/utils/request';
import { stringify } from 'qs';
// function stringifyUrlQuery(o) {
//   if (typeof o === 'object') {
//     return Object.keys(o)
//       .reduce((r, c) => {
//         r.push(`${c}=${window.encodeURIComponent(o[c])}`);
//         return r;
//       }, [])
//       .join('&');
//   }
//
//   return null;
// }

export async function individualInfo(data) {
  return request(`/v1/kyc/new/retail/info`, {
    method: 'POST',
    data,
  });
}

export async function individualUpdateInfo(data) {
  return request(`/v1/kyc/new/retail/update`, {
    method: 'POST',
    data,
  });
}

const getKycInfoPromise = params =>
  request(`/v1/kyc/info?${stringify(params)}`, {
    method: 'GET',
  });

export async function fetchIndividualInfo(params) {
  return getKycInfoPromise(params);
}

export async function fetchInstitutionInfo(params) {
  return getKycInfoPromise(params);
}

export async function kycUpdate(data) {
  return request(`/v1/kyc/new/update/kycLevel`, {
    method: 'POST',
    data,
  });
}
export async function institutionInfo(data) {
  return request(`/v1/kyc/institution/info`, {
    method: 'POST',
    data,
  });
}

export async function backToBasic(params) {
  return request(`/v1/kyc/status/back?${stringify(params)}`, {
    method: 'GET',
  });
}

// export async function backToBasic(data) {
//   return request(`/v1/kyc/status/back`, {
//     method: 'POST',
//     data,
//   });
// }

export async function institutionUpdateInfo(data) {
  return request(`/v1/kyc/institution/update`, {
    method: 'POST',
    data,
  });
}

export async function proofAddressUpload(data) {
  return request(`/v1/kyc/upload/document`, {
    method: 'POST',
    body: data,
  });
}

export async function individualReviewResult() {
  return getKycInfoPromise();
}

export async function institutionReviewResult() {
  return getKycInfoPromise();
}

export async function fetchKycDocumentsInfo(params) {
  return request(`/v1/kyc/info/documents?${stringify(params)}`, {
    method: 'GET',
  });
}

export async function getDocumentsByBlockIds(data) {
  return request(`/v1/kyc/stream-by-ids`, {
    method: 'POST',
    data,
  });
}

export async function checkId(data) {
  return request(`/v1/kyc/retail/check-id`, {
    method: 'POST',
    data,
  });
}