import Big from 'big.js';
import { formatMessage } from 'umi-plugin-react/locale';
import { message } from 'antd';
import filter from 'lodash/filter';
import find from 'lodash/find';
import size from 'lodash/size';
import get from 'lodash/get';
import head from 'lodash/head';
import debounce from 'lodash/debounce';
import lowerCase from 'lodash/lowerCase';
import throttle from 'lodash/throttle';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';

const { FRONTEND_CRYPTO_CAPTCHA_GOOGLE_SECRET } = process.env;

const packageJson =
  process.env.NODE_ENV === 'production'
    ? // Use prod's package.json to protect sensitive data
      require('../../package.prod.json')
    : require('../../package.json');
export const RecaptchaSitekey = FRONTEND_CRYPTO_CAPTCHA_GOOGLE_SECRET;
/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const STATUS_UNSUBMITTED = 'UNSUBMITTED';
export const STATUS_FILEUPLOADED = 'FILE_UPLOADED';
export const STATUS_APPROVED = 'APPROVED';

export const TYPE_RETAIL = 'RETAIL';
export const TYPE_INSTITUTION = 'INSTITUTION';

export function isValidType(type) {
  return type === TYPE_INSTITUTION || type === TYPE_RETAIL;
}

export const isUrl = path => reg.test(path);

export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};

export const ctor = () => {};
export const identity = k => k;

export const get24hChangePrecision = (openPrice, closePrice) => {
  try {
    return `${Big(closePrice)
      .minus(openPrice)
      .div(openPrice)
      .times(100)
      .toFixed(2)}%`;
  } catch (e) {
    return '--';
  }
};

export const get24hChange = (openPrice, closePrice) => {
  try {
    return (
      Big(closePrice)
        .minus(openPrice)
        .div(openPrice)
        .times(100)
        // .round(2, 0)
        .toFixed(2)
        .valueOf()
    );
  } catch (e) {
    return '0';
  }
};

/* eslint-disable no-console */
export const logTitle = (...args) => {
  localStorage.setItem('MATRIX_UI_VERSION', packageJson.version);
  console.info(
    `%cMatrix UI v${packageJson.version}%c`,
    'font-size: 20px; color: #C9AB8C; font-weight: bold; background-color: #000',
    '',
    ...args
  );
};
export const isSecurePassword = val => {
  const reUpperLetter = /[A-Z]/;
  const reNumber = /[0-9]/;
  return String(val).length > 7 && reUpperLetter.test(val) && reNumber.test(val);
};
export const isEnglishfield = val => {
  const fieldRule = /^[a-zA-Z]+$/;
  return fieldRule.test(val);
};

export const isEnglishAndSpacefield = val => {
  const fieldRule = /^[a-zA-Z ]+$/;
  return fieldRule.test(val);
};

export const isCustomInput = val => {
  const fieldRule = /^[a-zA-Z0-9&@£$€¥#.,:\-; ]+$/;
  return fieldRule.test(val);
};

export const showResponseError = (response, messages = {}) => {
  const { code, data } = response;
  if (code === 'PARAMETER_INVALID') {
    message.error(data);
  } else {
    const key = `Server.codes.${response.code || response.error}`;
    let content = formatMessage({ id: key });
    console.log("REFUND_RULES_NOT_FOUND", content)
    const isKeyNotExist = content === key;
    if (isKeyNotExist) {
      const msg = messages[response.code] || messages[response.error];
      if (msg) {
        content = formatMessage({ ...msg });
      } else {
        content = response.message || 'Internal Server Error';
      }
    }
    // Modal.error({
    //   title: formatMessage({ id: 'message.title.error' }),
    //   content,
    //   okText: formatMessage({ id: 'button.ok' }),
    // });
    message.error(content);
  }
};

export const filterSymbol = symbols =>
  filter(
    symbols,
    () => true
    // symbol => new Date().getTime() > symbol.startTime && new Date().getTime() < symbol.endTime
  );

export const hostname = window.location.hostname

export const localStore = {
  get(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      return null;
    }
  },
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove(key) {
    localStorage.removeItem(key);
  },


  setItem(key, value){
    localStorage.setItem(`${hostname}_${key}`, JSON.stringify(value))
  },
  
  getItem(key){
     try{
       return JSON.parse(localStorage.getItem(`${hostname}_${key}`))
     }catch(error){
       return null
     }
  },
  removeItem(key){
    localStorage.removeItem(`${hostname}_${key}`)
  }


};

export const LOCAL_SYMBOL = 'trade.symbol';
export const getSymbolByClient = (symbols, symbol) => {
  if (size(symbols) === 0) {
    return null;
  }
  let currentSymbol;
  const topSymbol = get(head(symbols), 'name');
  if (symbol) {
    currentSymbol = find(symbols, { name: symbol });
    if (currentSymbol) {
      localStore.set(LOCAL_SYMBOL, symbol);
      return symbol;
    }
  }
  currentSymbol = localStore.get(LOCAL_SYMBOL);
  if (currentSymbol && find(symbols, { name: currentSymbol })) {
    return currentSymbol;
  }
  currentSymbol = topSymbol;
  localStore.set(LOCAL_SYMBOL, currentSymbol);
  return currentSymbol;
};

export const debounceWrapper = (fn, time) => debounce(fn, time);

export const throttleWrapper = (fn, time) => throttle(fn, time);

export const getEnvFromHostname = hostname => {
  let env = null;
  // local default to test env
  if (hostname.includes('localhost') || hostname.includes("wangxiong.matrix.co")) {
    env = 'dev1';
  } else {
    const hostnameElements = hostname.split('.');
    if (hostnameElements.length <= 1) {
      // exceptional case
      env = 'test';
    } else if (hostnameElements[0].includes('test1')) {
      env = 'test1';
    } else if (hostnameElements[0].includes('test2')) {
      env = 'test2';
    } else if (hostnameElements[0].includes('test')) {
      env = 'test';
    } else if (hostnameElements[0].includes('dev1')) {
      env = 'dev1';
    } else if (hostnameElements[0].includes('dev2')) {
      env = 'dev2';
    } else if (hostnameElements[0].includes('dev3')) {
      env = 'dev3';
    } else if (hostnameElements[0].includes('benchmark')) {
      env = 'benchmark';
    } else if (hostnameElements[0].includes('uat')) {
      env = 'uat';
    } else if (hostnameElements[0].includes('dr')) {
      env = 'dr';
    } else if (hostnameElements[0].includes('maker')) {
      env = 'maker';
    } else {
      // first elem exists, not localhost, not anyone of the envs - it should be prod
      env = '';
    }
  }
  return env;
};

export const getApiEnv = currentLocation => {
  // When `currentLocation = window.location`
  const { hostname } = currentLocation || {};
  if (hostname) return getEnvFromHostname(hostname);

  const url = new URL(currentLocation);
  return getEnvFromHostname(url.hostname);
};

export const getHostnameSuffix = hostname => {
  const elems = hostname.split('.');
  if (elems.length <= 1) {
    return hostname;
  }
  return elems.slice(-2).join('.');
};

export const getWSHost = () => {
  const env = getApiEnv(window.location);
  if (window.location.hostname === 'localhost' && process.env.wsUrl) return process.env.wsUrl;
  const nonFirstPart = getHostnameSuffix(window.location.hostname);
  return env ? `wss://mtx-wss-${env}.${nonFirstPart}/` : `wss://mtx-wss.${nonFirstPart}/`;
};
function hasStatusKey(profile) {
  return profile && 'kycType' in profile;
}
export function isApprovedStatus(profile) {
  return hasStatusKey(profile) && profile.kycStatus === STATUS_APPROVED;
}

export function parseTime(time, cFormat) {
  let copyTime = cloneDeep(time);
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (time.toString().length === 10) {
      copyTime = parseInt(copyTime, 10) * 1000;
    }
    if (typeof time === 'string') {
      copyTime = parseInt(copyTime, 10);
    }
    date = new Date(copyTime);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = `0${value}`;
    }
    return value || 0;
  });
  return timeStr;
}

export const localeMessage = (id, values = undefined) => {
  const descriptor = typeof id === 'object' ? id : { id };
  return values ? formatMessage(descriptor, values) : formatMessage(descriptor);
};

/**
 * Get data from AntD/components/table/Column
 *
 * @param {Object} column column config
 * @param {Object} data data
 * @param {Any} fallback fallback data
 */
export const dataFromColumnRender = (column, data, fallback = '') => {
  // Refer: https://github.com/ant-design/ant-design/blob/1bf0bab2a7bc0a774119f501806e3e0e3a6ba283/components/table/Column.tsx
  const { render, dataIndex } = column;

  // Refer: https://github.com/ant-design/ant-design/blob/1bf0bab2a7bc0a774119f501806e3e0e3a6ba283/components/table/Column.tsx#L8
  if (typeof render === 'function') return render(data[dataIndex], data, dataIndex);
  return data[dataIndex] !== undefined ? data[dataIndex] : fallback;
};

/**
 * Is it edit mode ?
 * @param {Object} data data
 * @param {String} editKey key for test edit mode
 */
export const isEditData = (data, editKey = 'id') => {
  if (data && Object.prototype.hasOwnProperty.call(data, editKey)) return true;
  return false;
};

export const itemCallbackByColumns = columns => item =>
  columns.reduce((r, column) => {
    const { title } = column;
    // eslint-disable-next-line no-param-reassign
    r[title] = dataFromColumnRender(column, item);
    return r;
  }, {});

export function toAntPagination(p) {
  return Object.assign({}, p, { current: p.pageNumber });
}

export function toInternalPaginationQueries(p) {
  return {
    pageNumber: p.current,
    pageSize: p.pageSize,
  };
}

export const objectHasSet = (obj, ...props) => {
  if (!obj || !isPlainObject(obj)) return false;

  return !!props.reduce((r, p) => {
    // eslint-disable-next-line no-bitwise, no-param-reassign
    r &= obj[p] !== undefined ? 1 : 0;
    return r;
  }, 1);
};

// 当form表单作为弹窗时需要传fieldsValue, 因为弹窗隐藏后fieldsError里面的值会被清空
export const hasErrors = (fieldsError, fieldsValue) => {
  const r1 = Object.keys(fieldsError).some(field => fieldsError[field]);
  if (r1) {
    return true;
  }
  if (fieldsValue) {
    return Object.keys(fieldsValue).every(field => typeof fieldsValue[field] === 'undefined');
  }
  return false;
};

// 数字添加千分位
export const formatNumber = number => {
  const numStr = number.toString();
  const regPattern = /\d{1,3}(?=(\d{3})+$)/g;
  if (numStr.indexOf('.') > -1) {
    const [z, x] = numStr.split('.');
    const zs = z.replace(regPattern, '$&,');
    const xs = x
      .split('')
      .reverse()
      .join('')
      .replace(regPattern, '$&,')
      .split('')
      .reverse()
      .join('');
    // const xs = x.replace(regPattern, '$&,');
    return `${zs}.${xs}`;
  }
  return numStr.replace(regPattern, '$&,');
};

export const checkImgExists = imgUrl => {
  const ImgObj = new Image(); // 判断图片是否存在
  ImgObj.src = imgUrl;
  ImgObj.onload = () =>
    // 没有图片，则返回-1
    ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0);
};

export const getCurrentFrom = state => {
  const search = get(window.location, 'search', '');
  if (search) {
    const strSearchPair = search.split('?')[1];
    return strSearchPair &&
      lowerCase(strSearchPair) !== 'login' &&
      lowerCase(strSearchPair) !== 'register'
      ? strSearchPair
      : '/';
  }
  // 还有多处修要修改
  const pathname = get(window.location, 'pathname', '')
  if(pathname === '/blockTrading/home'){
    return '/blockTrading/home'
  }

  return state;
};
export const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]';


export  function swap(array, first, second) {
  var tmp = array[second];
  array[second] = array[first];
  array[first] = tmp;
  return array; 
}