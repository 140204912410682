import React from 'react';
import PropTypes from 'prop-types';

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ("value" in descriptor) descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps);
  if (staticProps) _defineProperties(Constructor, staticProps);
  return Constructor;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function");
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      writable: true,
      configurable: true
    }
  });
  if (superClass) _setPrototypeOf(subClass, superClass);
}

function _getPrototypeOf(o) {
  _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) {
    return o.__proto__ || Object.getPrototypeOf(o);
  };
  return _getPrototypeOf(o);
}

function _setPrototypeOf(o, p) {
  _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) {
    o.__proto__ = p;
    return o;
  };

  return _setPrototypeOf(o, p);
}

function _assertThisInitialized(self) {
  if (self === void 0) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return self;
}

function _possibleConstructorReturn(self, call) {
  if (call && (typeof call === "object" || typeof call === "function")) {
    return call;
  }

  return _assertThisInitialized(self);
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function zeroPad(value) {
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var strValue = String(value);
  if (length === 0) return strValue;
  var match = strValue.match(/(.*?)([0-9]+)(.*)/);
  var prefix = match ? match[1] : '';
  var suffix = match ? match[3] : '';
  var strNo = match ? match[2] : strValue;
  var paddedNo = strNo.length >= length ? strNo : (_toConsumableArray(Array(length)).map(function () {
    return '0';
  }).join('') + strNo).slice(length * -1);
  return "".concat(prefix).concat(paddedNo).concat(suffix);
}
var timeDeltaFormatOptionsDefaults = {
  daysInHours: false,
  zeroPadTime: 2
};
function calcTimeDelta(date) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$now = _ref.now,
      now = _ref$now === void 0 ? Date.now : _ref$now,
      _ref$precision = _ref.precision,
      precision = _ref$precision === void 0 ? 0 : _ref$precision,
      _ref$controlled = _ref.controlled,
      controlled = _ref$controlled === void 0 ? false : _ref$controlled,
      _ref$offsetTime = _ref.offsetTime,
      offsetTime = _ref$offsetTime === void 0 ? 0 : _ref$offsetTime;

  var startTimestamp;

  if (typeof date === 'string') {
    startTimestamp = new Date(date).getTime();
  } else if (date instanceof Date) {
    startTimestamp = date.getTime();
  } else {
    startTimestamp = date;
  }

  if (!controlled) {
    startTimestamp += offsetTime;
  }

  var total = Math.round(parseFloat((Math.max(0, controlled ? startTimestamp : startTimestamp - now()) / 1000).toFixed(Math.max(0, Math.min(20, precision)))) * 1000);
  var seconds = total / 1000;
  return {
    total: total,
    days: Math.floor(seconds / (3600 * 24)),
    hours: Math.floor(seconds / 3600 % 24),
    minutes: Math.floor(seconds / 60 % 60),
    seconds: Math.floor(seconds % 60),
    milliseconds: Number((seconds % 1 * 1000).toFixed()),
    completed: total <= 0
  };
}
function formatTimeDelta(delta, options) {
  var days = delta.days,
      hours = delta.hours,
      minutes = delta.minutes,
      seconds = delta.seconds;

  var _Object$assign = Object.assign(Object.assign({}, timeDeltaFormatOptionsDefaults), options),
      daysInHours = _Object$assign.daysInHours,
      zeroPadTime = _Object$assign.zeroPadTime,
      _Object$assign$zeroPa = _Object$assign.zeroPadDays,
      zeroPadDays = _Object$assign$zeroPa === void 0 ? zeroPadTime : _Object$assign$zeroPa;

  var formattedHours = daysInHours ? zeroPad(hours + days * 24, zeroPadTime) : zeroPad(hours, Math.min(2, zeroPadTime));
  return {
    days: daysInHours ? '' : zeroPad(days, zeroPadDays),
    hours: formattedHours,
    minutes: zeroPad(minutes, Math.min(2, zeroPadTime)),
    seconds: zeroPad(seconds, Math.min(2, zeroPadTime))
  };
}

var isEqual = require('lodash.isequal');

var Countdown = function (_React$Component) {
  _inherits(Countdown, _React$Component);

  function Countdown(props) {
    var _this;

    _classCallCheck(this, Countdown);

    _this = _possibleConstructorReturn(this, _getPrototypeOf(Countdown).call(this, props));
    _this.mounted = false;

    _this.tick = function () {
      var onTick = _this.props.onTick;

      var timeDelta = _this.calcTimeDelta();

      _this.setTimeDeltaState(Object.assign({}, timeDelta));

      if (onTick && timeDelta.total > 0) {
        onTick(timeDelta);
      }
    };

    _this.start = function () {
      _this.setState(function (_ref) {
        var offsetStart = _ref.offsetStart,
            offsetTime = _ref.offsetTime;
        return {
          offsetStart: 0,
          offsetTime: offsetTime + (offsetStart ? Date.now() - offsetStart : 0)
        };
      }, function () {
        var timeDelta = _this.calcTimeDelta();

        _this.setTimeDeltaState(timeDelta);

        _this.props.onStart && _this.props.onStart(timeDelta);

        if (!_this.props.controlled) {
          _this.clearInterval();

          _this.interval = window.setInterval(_this.tick, _this.props.intervalDelay);
        }
      });
    };

    _this.pause = function () {
      _this.clearInterval();

      _this.setState({
        offsetStart: _this.calcOffsetStart()
      }, function () {
        var timeDelta = _this.calcTimeDelta();

        _this.setTimeDeltaState(timeDelta);

        _this.props.onPause && _this.props.onPause(timeDelta);
      });
    };

    _this.isPaused = function () {
      return _this.state.offsetStart > 0;
    };

    _this.isCompleted = function () {
      return _this.state.timeDelta.completed;
    };

    _this.state = {
      timeDelta: _this.calcTimeDelta(),
      offsetStart: props.autoStart ? 0 : _this.calcOffsetStart(),
      offsetTime: 0
    };
    return _this;
  }

  _createClass(Countdown, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.mounted = true;
      this.props.autoStart && this.start();
      this.props.onMount && this.props.onMount(this.calcTimeDelta());
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (!isEqual(this.props, prevProps)) {
        this.setTimeDeltaState(this.calcTimeDelta());
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.mounted = false;
      this.clearInterval();
    }
  }, {
    key: "calcTimeDelta",
    value: function calcTimeDelta$1() {
      var _this$props = this.props,
          date = _this$props.date,
          now = _this$props.now,
          precision = _this$props.precision,
          controlled = _this$props.controlled;
      return calcTimeDelta(date, {
        now: now,
        precision: precision,
        controlled: controlled,
        offsetTime: this.state ? this.state.offsetTime : 0
      });
    }
  }, {
    key: "calcOffsetStart",
    value: function calcOffsetStart() {
      return Date.now();
    }
  }, {
    key: "clearInterval",
    value: function clearInterval() {
      window.clearInterval(this.interval);
    }
  }, {
    key: "setTimeDeltaState",
    value: function setTimeDeltaState(timeDelta) {
      var _this2 = this;

      var callback;

      if (!this.state.timeDelta.completed && timeDelta.completed) {
        this.clearInterval();

        callback = function callback() {
          return _this2.props.onComplete && _this2.props.onComplete(timeDelta);
        };
      }

      if (this.mounted) {
        return this.setState({
          timeDelta: timeDelta
        }, callback);
      }
    }
  }, {
    key: "getApi",
    value: function getApi() {
      return this.api = this.api || {
        start: this.start,
        pause: this.pause,
        isPaused: this.isPaused,
        isCompleted: this.isCompleted
      };
    }
  }, {
    key: "getRenderProps",
    value: function getRenderProps() {
      var _this$props2 = this.props,
          daysInHours = _this$props2.daysInHours,
          zeroPadTime = _this$props2.zeroPadTime,
          zeroPadDays = _this$props2.zeroPadDays;
      var timeDelta = this.state.timeDelta;
      return Object.assign(Object.assign({}, timeDelta), {
        api: this.getApi(),
        props: this.props,
        formatted: formatTimeDelta(timeDelta, {
          daysInHours: daysInHours,
          zeroPadTime: zeroPadTime,
          zeroPadDays: zeroPadDays
        })
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props3 = this.props,
          children = _this$props3.children,
          renderer = _this$props3.renderer;
      var renderProps = this.getRenderProps();

      if (renderer) {
        return renderer(renderProps);
      }

      if (children && this.state.timeDelta.completed) {
        return React.cloneElement(children, {
          countdown: renderProps
        });
      }

      var _renderProps$formatte = renderProps.formatted,
          days = _renderProps$formatte.days,
          hours = _renderProps$formatte.hours,
          minutes = _renderProps$formatte.minutes,
          seconds = _renderProps$formatte.seconds;
      return React.createElement("span", null, days, days ? ':' : '', hours, ":", minutes, ":", seconds);
    }
  }]);

  return Countdown;
}(React.Component);
Countdown.defaultProps = Object.assign(Object.assign({}, timeDeltaFormatOptionsDefaults), {
  controlled: false,
  intervalDelay: 1000,
  precision: 0,
  autoStart: true
});
Countdown.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]).isRequired,
  daysInHours: PropTypes.bool,
  zeroPadTime: PropTypes.number,
  zeroPadDays: PropTypes.number,
  controlled: PropTypes.bool,
  intervalDelay: PropTypes.number,
  precision: PropTypes.number,
  autoStart: PropTypes.bool,
  children: PropTypes.element,
  renderer: PropTypes.func,
  now: PropTypes.func,
  onMount: PropTypes.func,
  onStart: PropTypes.func,
  onPause: PropTypes.func,
  onTick: PropTypes.func,
  onComplete: PropTypes.func
};

export default Countdown;
export { calcTimeDelta, formatTimeDelta, zeroPad };
